<template>
  <div
    :class="[
      $style.table__content_row,
      order.status.name === 'Закрытый' && $style.table__content_row_active
    ]"
    id="table__content_row"
  >
    <div :class="$style.table__content_column">
      {{ order.order.id }}
    </div>
    <div :class="$style.table__content_column" @click="() => setOrder(order.order)">
      {{
        order.order.datetime
          ? moment(order.order.datetime).format('YYYY-MM-DD  LT')
          : 'Не заполнено'
      }}
    </div>
    <div :class="$style.table__content_column" @click="() => setOrder(order.order)">
      {{ order && order.status ? order.status.name : 'Не заполнено' }}
    </div>
    <div :class="$style.table__content_column" @click="() => setOrder(order.order)">
      {{
        order.order.teacher &&
        `${order.order.teacher.attributes.lastName} ${order.order.teacher.attributes.firstName} ${order.order.teacher.attributes.middleName} (id: ${order.order.teacher.id})`
      }}
    </div>

    <div :class="$style.table__content_column" @click="() => setOrder(order.order)">
      {{
        `${order.order.student.attributes.lastName} ${order.order.student.attributes.firstName} ${order.order.student.attributes.middleName} (id: ${order.order.student.id})`
      }}
    </div>
    <div :class="$style.table__content_column" @click="() => setOrder(order.order)">
      {{
        `${order.initiator.attributes.lastName} ${order.initiator.attributes.firstName} ${order.initiator.attributes.middleName} (id: ${order.initiator.id})`
      }}
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Moment from 'moment'

export default {
  name: 'TableRow',
  props: ['order'],
  computed: {
    moment() {
      return Moment
    },
    firstOrder() {
      return this.order.find((item) => item.id === 1)
    }
  },
  methods: {
    ...mapMutations(['setOrderDetails']),

    setOrder(order) {
      this.setOrderDetails(order)
      this.$router.push(`/arbitrations/${order.id}`)
    }
  }
}
</script>

<style module lang="scss">
@import '@/assets/styles/colors.scss';

.table {
  &__content {
    &_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //height: 2.5rem;
      border-bottom: 1px solid $table-border;
      cursor: pointer;
      transition: all 0.3s ease;
      &_active {
        background: red;
        border-right: 1px solid $table-border;
      }

      &:hover {
        background: #e7e4e4;

        opacity: 0.8;
      }
    }
    &_column {
      display: flex;
      align-items: center;
      padding: 0.7rem 1rem;
      font-size: 0.875rem;
      font-weight: 500;

      &:first-child {
        width: 9.5%;
        justify-content: center;
      }

      &:nth-child(n + 2) {
        width: 18.5%;
      }

      &:last-child {
        border: none;
      }
    }

    &_rowLine {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
    }

    &_line {
      border-right: 1px solid $table-border;

      &:first-child {
        width: 7.5%;
      }

      &:nth-child(n + 2) {
        width: 18.5%;
      }

      &:last-child {
        border: none;
      }
    }
  }
}
</style>
